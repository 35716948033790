"use client";

import { ACL } from "wordparrot-types";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import { useObservable } from "react-use";
import Head from "next/head";

import { site$ } from "state/site/query";

import AppWrapper from "wrapper";
import CheckPermissions from "modules/_common/atoms/CheckPermissions";
import FormUIContainer from "modules/_common/pages/FormUIContainer/FormUIContainer";
import Loading from "modules/_common/pages/Loading";
import LoadingState from "modules/_common/organisms/LoadingState";
import Login from "modules/login/Pages/Login";
import SetupComponent from "setup/setup";
import Snackbar from "modules/_common/atoms/Snackbar";

import * as routingConstants from "lib/routing";
import { bootstrapApplication } from "api/bootstrap";
import { snackbarService } from "services/Snackbar";
import { useFetchAuth } from "api/auth";
import { useFetchSite } from "api/site";
import NotFound from "app/not-found";

const AppPage = lazy(() => import("modules/app/Pages/App"));
const Dashboard = lazy(() => import("modules/dashboard/Pages/Dashboard"));
const PromptDisplay = lazy(() => import("modules/prompt/Pages/PromptDisplay"));
const CredentialFormDisplay = lazy(
  () => import("modules/credential/Pages/CredentialFormDisplay"),
);
const PrivacyPolicy = lazy(() => import("modules/privacy/Pages/PrivacyPolicy"));
const Logout = lazy(() => import("modules/login/Pages/Logout"));

type BootstrapStatus =
  | "start"
  | "fetchSite"
  | "fetchAuth"
  | "completed"
  | "error";

const Main = () => {
  const [bootstrapStatus, setBootstrapStatus] =
    useState<BootstrapStatus>("start");
  const history = useHistory();

  useEffect(() => {
    void bootstrapApplication({
      onSuccess: () => {
        setBootstrapStatus("fetchSite");
      },
      onError: () => {
        setBootstrapStatus("error");
        snackbarService.next({
          show: true,
          message: "Cannot finish app setup - check your connection.",
          type: "error",
        });
        history.push(routingConstants.ROUTE_NOT_FOUND);
      },
    });
  }, [history]);

  useFetchSite(undefined, {
    enabled: bootstrapStatus === "fetchSite",
    cacheTime: 10000,
    staleTime: 30000,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setBootstrapStatus("fetchAuth");
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (err?.message === "setup_required") {
        history.push(routingConstants.ROUTE_SETUP);
      } else {
        history.push(routingConstants.ROUTE_NOT_FOUND);
      }
      setBootstrapStatus("completed");
    },
  });

  useFetchAuth({
    enabled: bootstrapStatus === "fetchAuth",
    cacheTime: 10000,
    staleTime: 30000,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setBootstrapStatus("completed");
    },
    onError: () => {
      setBootstrapStatus("completed");
    },
  });

  const site = useObservable(site$);

  if (
    bootstrapStatus === "start" ||
    bootstrapStatus === "fetchSite" ||
    bootstrapStatus === "fetchAuth" ||
    !site
  ) {
    return (
      <AppWrapper>
        <div className="Loading">
          <Loading />
        </div>
      </AppWrapper>
    );
  }

  return (
    <div className="App">
      <LoadingState />
      <Snackbar />
      {/* {deviceService.isMobile && <AppUrlListener />} */}
      <Switch>
        <Route path={routingConstants.ROUTE_SETUP}>
          <SetupComponent />
        </Route>
        <Route path={routingConstants.ROUTE_APP_PREFIX}>
          <CheckPermissions
            permissions={[ACL.ADMIN_SUPER]}
            redirectTo={routingConstants.ROUTE_LOGIN}
          >
            <AppPage />
          </CheckPermissions>
        </Route>
        <Route path={routingConstants.ROUTE_DASHBOARD_PREFIX}>
          <CheckPermissions
            permissions={[ACL.ADMIN_SUPER]}
            redirectTo={routingConstants.ROUTE_LOGIN}
          >
            <Dashboard />
          </CheckPermissions>
        </Route>
        <Route path={routingConstants.ROUTE_LOGIN}>
          <>
            <Head>
              <title>{`Login | ${site.title}`}</title>
            </Head>
            <FormUIContainer centered>
                <div>
                  <Login />
                </div>
            </FormUIContainer>
          </>
        </Route>
        <Route
          path={[
            routingConstants.ROUTE_PROMPTS_DISPLAY_NO_DASHBOARD,
            routingConstants.ROUTE_PROMPTS_DISPLAY_NO_DASHBOARD_ALT,
          ]}
        >
          <CheckPermissions
            permissions={[ACL.PROJECT_READ]}
            redirectTo={routingConstants.ROUTE_LOGIN}
          >
            <>
              <Head>
                <title>{`Form | ${site.title}`}</title>
              </Head>
              <FormUIContainer xs={12} sm={12} md={10} lg={8} xl={6}>
                  <div>
                    <PromptDisplay />
                  </div>
              </FormUIContainer>
            </>
          </CheckPermissions>
        </Route>
        <Route path={routingConstants.ROUTE_CREDENTIAL_FORMS_DISPLAY}>
          <CheckPermissions
            permissions={[ACL.PROJECT_READ]}
            redirectTo={routingConstants.ROUTE_LOGIN}
          >
            <>
              <Head>
                <title>{`Create Credential | ${site.title}`}</title>
              </Head>
              <FormUIContainer xs={12} sm={12} md={10} lg={8} xl={8}>
                  <div>
                    <CredentialFormDisplay />
                  </div>
              </FormUIContainer>
            </>
          </CheckPermissions>
        </Route>
        <Route path={routingConstants.ROUTE_LOGOUT}>
          <Logout />
        </Route>
        <Route exact path={[routingConstants.ROUTE_PRIVACY_POLICY]}>
          <>
            <Head>
              <title>{`Privacy Policy | ${site.title}`}</title>
            </Head>
            <FormUIContainer>
                <div>
                  <PrivacyPolicy />
                </div>
            </FormUIContainer>
          </>
        </Route>
        <Route path={routingConstants.ROUTE_NOT_FOUND}>
          <>
            <Head>
              <title>{`Not Found | ${site.title || ""}`}</title>
            </Head>
            <FormUIContainer>
                <NotFound />
            </FormUIContainer>
          </>
        </Route>
        <Route path={"/"}>
          <Redirect to={routingConstants.ROUTE_DASHBOARD_MAIN} />
        </Route>
        <Route path={"*"}>
          <Redirect to={routingConstants.ROUTE_NOT_FOUND} />
        </Route>
      </Switch>
    </div>
  );
};

export default Main;
