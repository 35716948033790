import { AuthorizedProfile } from "wordparrot-types";
import { GetResult } from "@capacitor/storage";
import { Observable, firstValueFrom, from, of } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";

import { ApiResponse, get, post, put } from "lib/api";
import { AppConfig } from "config";
import {
  deviceService,
  pushNotificationService,
  storageService,
} from "services/Capacitor";
import { domainService } from "services/Domain";
import { sessionService } from "state/session/service";

import * as authConstants from "constants/auth";
import * as loginConstants from "constants/login";
import * as siteConstants from "constants/sites";
import * as userConstants from "constants/users";

export const setTimezone = (
  userId: string,
  timezone: string,
): Promise<ApiResponse<null>> => {
  return firstValueFrom(
    put<ApiResponse<null>>(
      `${domainService.apiRoot}/${userConstants.USERS}/${userId}/${userConstants.SET_TIMEZONE}`,
      {
        body: {
          timezone,
        },
      },
    ),
  );
};

export const registerProfile = (authorizedProfile: AuthorizedProfile): void => {
  if (deviceService.isMobile) {
    pushNotificationService.register(authorizedProfile);
  }
  sessionService.updateProfile(authorizedProfile);
  sessionService.updateAuth({
    hasReceivedAuth: true,
    isLoggedIn: true,
    acl: authorizedProfile.acl,
  });
};

class AuthService {
  refreshWebWorker: Worker;

  constructor() {
    void (async () => {
      try {
        setInterval(() => {
          this.refresh().subscribe();
        }, 3000000);
      } catch (e) {
        // Cannot refresh access token + csrf
      }
    })();
  }

  initRefreshWebWorker(): void {
    this.refreshWebWorker = new Worker("/workers/refresh.js");
    this.refreshWebWorker.addEventListener("message", async (e) => {
      await firstValueFrom(this.refresh());
    });
  }

  login(form: LoginForm, saveInfo = false) {
    return post<
      ApiResponse<{
        refreshToken: string;
        accessToken: string;
        csrfToken: string;
      }>
    >(
      `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${loginConstants.LOGIN}`,
      {
        body: form,
      },
    ).pipe(
      map((response) => response.data),
      switchMap((responseData) => {
        // //iOS override - token needs to be stored in local storage.
        if (deviceService.isOniOS) {
          return from(
            Promise.all([
              storageService.set({
                key: AppConfig.siteRefreshCookieName,
                value: responseData.refreshToken,
              }),
              storageService.set({
                key: AppConfig.siteAccessCookieName,
                value: responseData.accessToken,
              }),
              storageService.set({
                key: AppConfig.siteCsrfCookieName,
                value: responseData.csrfToken,
              }),
            ]),
          );
        }
        return of(undefined);
      }),
      tap(() => {
        if (saveInfo) {
          if (deviceService.isMobile) {
            void storageService.set({
              key: AppConfig.localStorageLoginInfo,
              value: JSON.stringify(form),
            });
          } else {
            window.localStorage.setItem(
              AppConfig.localStorageLoginInfo,
              JSON.stringify(form),
            );
          }
        } else {
          if (deviceService.isMobile) {
            void storageService.remove({
              key: AppConfig.localStorageLoginInfo,
            });
          } else {
            window.localStorage.removeItem(AppConfig.localStorageLoginInfo);
          }
        }
      }),
    );
  }

  forgotPassword(form: ForgotPasswordForm): Observable<void> {
    return post<void>(
      `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${loginConstants.FORGOT_PASSWORD}`,
      {
        body: form,
      },
    );
  }

  resetPassword(form: ResetPasswordForm): Observable<void> {
    return post<void>(
      `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${loginConstants.RESET_PASSWORD}`,
      {
        body: form,
      },
    );
  }

  fetchAuth(): Observable<AuthorizedProfile> {
    let obs: Observable<any>;

    if (deviceService.isOniOS) {
      // iOS override - must attach refresh JWT to headers.
      obs = from(
        storageService.get({
          key: AppConfig.siteRefreshCookieName,
        }),
      ).pipe(
        switchMap((refreshValue: GetResult) => {
          if (!refreshValue.value) {
            throw new Error("No refresh token present.");
          }

          return get<
            ApiResponse<{
              tokens: {
                accessToken: string;
                csrfToken: string;
                hubAccessToken?: string;
              };
              authorizedProfile: AuthorizedProfile;
            }>
          >(
            `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${authConstants.VERIFY}`,
            {
              body: {},
              headers: {
                [AppConfig.refreshHeader]: refreshValue.value || "",
              },
            },
          );
        }),
      );
    } else {
      obs = get<
        ApiResponse<{
          tokens: {
            accessToken: string;
            csrfToken: string;
            hubAccessToken?: string;
          };
          authorizedProfile: AuthorizedProfile;
        }>
      >(
        `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${authConstants.VERIFY}`,
      );
    }

    return obs.pipe(
      switchMap(
        (
          response: ApiResponse<{
            tokens: {
              accessToken: string;
              csrfToken: string;
              hubAccessToken?: string;
            };
            authorizedProfile: AuthorizedProfile;
          }>,
        ) => {
          if (!response.result) {
            console.log('Log: authentication failed.')
            return of({} as AuthorizedProfile);
          }

          const responseData = response.data;

          if (deviceService.isOniOS) {
            const toSet = [
              storageService.set({
                key: AppConfig.siteAccessCookieName,
                value: responseData.tokens.accessToken,
              }),
              storageService.set({
                key: AppConfig.siteCsrfCookieName,
                value: responseData.tokens.csrfToken,
              }),
            ];

            if (responseData.tokens.hubAccessToken) {
              toSet.push(
                storageService.set({
                  key: AppConfig.hubAccessToken,
                  value: responseData.tokens.hubAccessToken,
                }),
              );
              sessionService.updateProfile({
                hasHubToken: true,
              });
            }

            // iOS HTTP override - need to save refreshed tokens to local storage. Other platforms don't require this step.
            return from(Promise.all(toSet)).pipe(
              map(() => responseData.authorizedProfile),
            );
          } else {
            if (responseData.tokens.hubAccessToken) {
              localStorage.setItem(
                AppConfig.hubAccessToken,
                responseData.tokens.hubAccessToken,
              );
              sessionService.updateProfile({
                hasHubToken: true,
              });
            }
          }

          return of(responseData.authorizedProfile);
        },
      ),
      tap(registerProfile),
      tap(() => {
        this.initRefreshWebWorker();
      }),
    );
  }

  refresh(): Observable<any> {
    let obs: Observable<any>;

    if (deviceService.isOniOS) {
      // iOS override - must attach refresh JWT to headers.
      obs = from(
        storageService.get({
          key: AppConfig.siteRefreshCookieName,
        }),
      ).pipe(
        switchMap((refreshValue: GetResult) => {
          if (!refreshValue.value) {
            throw new Error("No refresh token present.");
          }

          return get<
            ApiResponse<{
              tokens: {
                accessToken: string;
                csrfToken: string;
              };
            }>
          >(
            `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${authConstants.REFRESH}`,
            {
              body: {},
              headers: {
                [AppConfig.refreshHeader]: refreshValue.value || "",
              },
            },
          );
        }),
      );
    } else {
      obs = get<
        ApiResponse<{
          tokens: {
            accessToken: string;
            csrfToken: string;
          };
        }>
      >(
        `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${authConstants.REFRESH}`,
      );
    }

    return obs.pipe(
      switchMap(
        (
          response: ApiResponse<{
            tokens: {
              accessToken: string;
              csrfToken: string;
              hubAccessToken?: string;
            };
          }>,
        ) => {
          if (!response.result) {
            throw new Error("Auth verification failed");
          }

          const responseData = response.data;

          if (deviceService.isOniOS) {
            // iOS HTTP override - need to save refreshed tokens to local storage. Other platforms don't require this step.
            return from(
              Promise.all([
                storageService.set({
                  key: AppConfig.siteAccessCookieName,
                  value: responseData.tokens.accessToken,
                }),
                storageService.set({
                  key: AppConfig.siteCsrfCookieName,
                  value: responseData.tokens.csrfToken,
                }),
              ]),
            );
          }

          return of(responseData);
        },
      ),
      catchError((e) => {
        // Swallow all errors here.
        return of({});
      }),
      // We only want the first value, so unsubscribe here.
      take(1),
    );
  }

  initiateChangeDomain(config: {
    domain: string;
  }): Observable<{ key: string }> {
    const { domain } = config;
    return get<ApiResponse<{ key: string }>>(
      `${domainService.apiRoot}/${authConstants.AUTH}/${siteConstants.SITES}/${
        authConstants.INITIATE_CHANGE_DOMAIN
      }?domain=${encodeURIComponent(domain)}`,
    ).pipe(map((response) => response.data));
  }
}

export interface SignupForm {
  email: string;
  password: string;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface ForgotPasswordForm {
  email: string;
}

export interface ResetPasswordForm {
  email: string;
  key: string;
  password: string;
  confirm: string;
}

export const authService = new AuthService();
